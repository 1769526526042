<template>
  <div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('province') }">
      <div class="left--col">
        <label for="province-id-field">
          <span>{{ $t('general.province') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <multiselect
          id="province-id-field"
          name="province"
          v-model="province"
          :options="provinces"
          track-by="id"
          label="name"
          :searchable="true"
          :close-on-select="true"
          :class="validation.hasError('province') ? 'is-invalid' : ''"
          :show-labels="true"
          :placeholder="$t('addListing.placeholder.province')"
        >
        </multiselect>
        <span class="val-error" v-if="validation.hasError('province')">{{
          validation.firstError('province')
        }}</span>
      </div>
    </div>
    <listing-form-transition>
      <div
        v-show="openCity"
        class="form--group"
        :class="{ 'has-error': validation.hasError('city') }"
      >
        <div class="left--col">
          <label for="city-id-field">
            <span>{{ $t('general.city') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <multiselect
            id="city-id-field"
            name="city"
            v-model="city"
            :options="cities"
            track-by="id"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :class="validation.hasError('city') ? 'is-invalid' : ''"
            :show-labels="true"
            :placeholder="$t('addListing.placeholder.city')"
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('city')">{{
            validation.firstError('city')
          }}</span>
        </div>
      </div>
    </listing-form-transition>
    <listing-form-transition>
      <div v-show="city" class="form--group">
        <div class="left--col">
          <label for="district-id-field">
            <span>{{ $t('general.district') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <multiselect
            name="district"
            id="district-id-field"
            v-model="district"
            :options="districts"
            track-by="id"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            :placeholder="$t('addListing.placeholder.district')"
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('district')">{{
            validation.firstError('district')
          }}</span>
        </div>
      </div>
    </listing-form-transition>
    <listing-form-transition>
      <div class="form--group" v-show="district">
        <div class="left--col">
          <label for="village-id-field">
            <span>{{ $t('general.village') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <multiselect
            id="village-id-field"
            name="village"
            v-model="village"
            :options="villages"
            track-by="id"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            :placeholder="$t('addListing.placeholder.village')"
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('village')">{{
            validation.firstError('village')
          }}</span>
        </div>
      </div>
    </listing-form-transition>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
import { Validator } from 'simple-vue-validator';
import { mapState } from 'vuex';
import ListingFormTransition from '@/components/listing-form/listing-form-transition';

export default {
  name: 'regional-section',
  components: { Multiselect, ListingFormTransition },
  computed: {
    ...mapState({
      provinces: (state) => state.v2.listingForm.sect1.regionalListingForm.provinces,
      cities: (state) => state.v2.listingForm.sect1.regionalListingForm.cities,
      districts: (state) => state.v2.listingForm.sect1.regionalListingForm.districts,
      villages: (state) => state.v2.listingForm.sect1.regionalListingForm.villages,
      openDistrict: (state) => state.v2.listingForm.sect1.regionalListingForm.openDistrict,
      openCity: (state) => state.v2.listingForm.sect1.regionalListingForm.openCity,
    }),
    province: {
      get() {
        return this.$store.state.v2.listingForm.sect1.regionalListingForm.province;
      },
      set(value) {
        this.$store.dispatch('v2/listingForm/sect1/regionalListingForm/changeProvince', value);
        this.$store.dispatch('v2/listingForm/sect1/regionalListingForm/getCities', value);
      },
    },
    city: {
      get() {
        return this.$store.state.v2.listingForm.sect1.regionalListingForm.city;
      },
      set(value) {
        this.$store.dispatch('v2/listingForm/sect1/regionalListingForm/changeCity', value);
        this.$store.dispatch('v2/listingForm/sect1/regionalListingForm/getDistricts', value);
      },
    },
    district: {
      get() {
        return this.$store.state.v2.listingForm.sect1.regionalListingForm.district;
      },
      set(value) {
        this.$store.dispatch('v2/listingForm/sect1/regionalListingForm/changeDistrict', value);
        this.$store.dispatch('v2/listingForm/sect1/regionalListingForm/getVillages', value);
      },
    },
    village: {
      get() {
        return this.$store.state.v2.listingForm.sect1.regionalListingForm.village;
      },
      set(value) {
        this.$store.dispatch('v2/listingForm/sect1/regionalListingForm/changeVillage', value);
      },
    },
  },
  methods: {
    validate: function () {
      // console.log('validate province')
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
  },
  validators: {
    province(value) {
      return Validator.value(value).required(this.$i18n.t('errors.addListing.province.required'));
    },
    city(value) {
      return Validator.value(value).required(this.$i18n.t('errors.addListing.city.required'));
    },
    district(value) {
      return Validator.value(value).required(this.$i18n.t('errors.addListing.district.required'));
    },
    village(value) {
      return Validator.value(value).required(this.$i18n.t('errors.addListing.village.required'));
    },
  },
};
</script>

<style scoped></style>
